// eslint-disable @typescript-eslint/ban-ts-comment

/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import 'reflect-metadata';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'crypto-browserify';
import 'stream-browserify';

// @ts-ignore
(function () {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event: any, paramsArg: any) {
    const params = paramsArg || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  // @ts-ignore
  window.CustomEvent = CustomEvent;
})();
